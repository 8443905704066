@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/responsive';
@import '../../theme/button';

:global(#app) {
  .customedCoachMediaResourceGrid {
    margin-bottom: 0;
  }

  .pagination {
    margin-left: 2em;

    @include Responsive(MD) {
      margin-top: 0.5rem;
      margin-left: 0;
    }
  }

  .dropDown {
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: 1.5rem;
    border: 1px solid $gray_MPB;
    border-radius: 5px;

    i {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.sessionOverlay {
  margin-top: 10px;
  .themeContainer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 50px;
    row-gap: 32px;
    color: red;
    z-index: 10;

    @include Responsive(LG) {
      justify-content: center;
    }

    @include Responsive(SM) {
      flex-direction: column;
    }

    .themeItem {
      box-shadow: 1px 1px 5px 0px $gray_MBP_light;
      width: fit-content;

      @include Responsive(SM) {
        width: 100%;
      }

      &:hover {
        cursor: pointer;
      }

      .themeImage {
        width: 256px;
        height: 180px;
        border-bottom: 2px solid $red_MPB;

        @include Responsive(SM) {
          width: 100%;
          height: 100%;
        }
      }

      .themeTitle {
        font-weight: 600;
        font-size: 0.9rem;
        padding: 0.8rem 0;
        background-color: $white1;
        width: 100%;
        @include secondary_btn;
        border: unset;
        border-radius: unset;
        margin-top: -4px;
        text-align: center;
        text-transform: uppercase;

        @include Responsive(SM) {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.wrapper {
  padding-right: 1rem;
}

.menuWrapper {
  padding-bottom: 1.2rem;
}

.toolsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  @include Responsive(XL) {
    flex-direction: column;
  }

  &.toolsWrapperHidden {
    display: none;
  }

  .backWrapper {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    color: $red1;

    &.backWrapperHidden {
      display: none;
    }

    .backIconWrapper {
      &:hover {
        cursor: pointer;
      }

      span {
        letter-spacing: 0.01rem;
        font-size: 14px;
        line-height: 19px;
        text-decoration: underline;
      }
    }
  }

  @include Responsive(MD) {
    flex-direction: column;
    align-items: center;
  }

  .toolsWrapperContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;

    @include Responsive(MD) {
      flex-direction: column;
    }
  }
}

.toolsBlock {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 1rem;

  @include Responsive(MD) {
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }
}

.searchWrapper {
  margin-right: 1rem;
}
